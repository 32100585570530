import {
  Alert,
  Button,
  FormControl,
  LinearProgress
} from '@mui/material';
import axios, { AxiosResponse } from 'axios';
import React, { useContext } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { BulkActionProps } from './props.interface';

function DeleteOrdersAction({
  selectedOrders,
  bulkEndpoint,
}: BulkActionProps) {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [message, setMessage] = React.useState<string>('');
  const [response, setResponse] = React.useState<AxiosResponse>();

  const { user } = useContext(AuthContext);
  const orgId = user?.organisation?.org_id
  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleSubmit = async () => {
    let endpoint = bulkEndpoint;
    if (orgId) {
      endpoint += `?org=${encodeURIComponent(orgId)}`;
    }

    console.log(endpoint);

    setIsLoading(true);
    setMessage('');
    setResponse(undefined);
    const requestData = Array.from(selectedOrders.keys()).map((order_pk) => {
      const order = {
        id: order_pk,
      };
      return order;
    });

    console.log(requestData);
    try {
      console.log(requestData);
      const response = await axios.delete<OrderType[]>(endpoint, {
        ...config,
        data: requestData,
      });
      setResponse(response);
      if (response.statusText == 'OK') {
        setMessage('Orders deleted successfully');
      } else {
        setMessage('Failed to delete orders');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.log('error: ', e);
        setResponse(e.response);
        setMessage('Failed to delete orders: ' + e.response?.statusText);
      } else {
        setMessage('Failed to delete orders');
      }
      console.log(e);
    }
    setIsLoading(false);
  };

  return (
    <>
      {isLoading ? (
        <LinearProgress />
      ) : (
        <>
          {message != '' && (
            <>
              <Alert
                severity={
                  response && response.status >= 200 && response.status < 300
                    ? 'success'
                    : 'error'
                }
              >
                <>
                  {message}

                  {response &&
                    response.status >= 300 &&
                    Object.keys(response.data).map((key) => (
                      <>
                        <ul>
                          {
                            typeof response.data[key] === "string" ?
                              <li>{response.data[key]}</li> :
                              response.data[key].map((validationError: string) => (
                                <li key={key}>
                                  {key}: {validationError}
                                </li>
                              ))}
                        </ul>
                      </>
                    ))}
                </>
              </Alert>
              <br /> </>
          )}
          <FormControl>
            <Button variant="contained" type="submit" onClick={handleSubmit}>
              {' '}
              Delete Orders{' '}
            </Button>
          </FormControl>{' '}

        </>
      )}
    </>
  );
}

export default DeleteOrdersAction;
