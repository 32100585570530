import {
  Alert,
  Button,
  Dialog,
  DialogContent,
  FormControl,
  IconButton,
  LinearProgress,
} from '@mui/material';
import { ClearIcon } from '@mui/x-date-pickers';
import axios, { AxiosResponse } from 'axios';
import React, { useContext } from 'react';
import { API_ENDPOINT } from '../../../configurations/global.config';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { BranchType } from '../../../interfaces/delivery-company/branches';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import CreateBranchForm from '../../delivery-company-components/branches/create-branch-form/create-branch-form';
import AutocompleteWithThrottle from '../autocomplete-with-throttle/autocomplete-with-throttle';
import { BulkActionProps } from './props.interface';

function SetBranchAction({
  selectedOrders,
  bulkEndpoint,
}: BulkActionProps) {
  const [isLoading, setIsLoading] = React.useState(false);
  const [branch, setBranch] = React.useState<BranchType | null>(null);
  const [creatingNewBranchOpen, setCreatingNewBranchOpen] =
    React.useState<boolean>(false);
  const [response, setResponse] = React.useState<AxiosResponse>();
  const [message, setMessage] = React.useState<string>('');

  const { user } = useContext(AuthContext);
  const orgId = user?.organisation?.org_id;

  const config = {
    headers: { Authorization: `Bearer ${user?.authToken}` },
  };

  const handleSubmit = async () => {
    const endpoint = bulkEndpoint;

    console.log(endpoint);
    setIsLoading(true);
    setMessage('');
    setResponse(undefined);
    const requestData = Array.from(selectedOrders.keys()).map((order_pk) => {
      const order = {
        id: order_pk,
        branch: branch,
      };
      return order;
    });

    console.log(requestData);
    try {
      const response = await axios.patch<OrderType[]>(
        endpoint,
        requestData,
        config
      );
      setResponse(response);
      if (response.statusText == 'OK') {
        setMessage('Orders updated successfully');
      } else {
        setMessage('Failed to update orders');
      }
    } catch (e) {
      if (axios.isAxiosError(e)) {
        console.log('error: ', e);
        setResponse(e.response);
        setMessage('Failed to update orders: ' + e.response?.statusText);
      } else {
        setMessage('Failed to update orders');
      }
      console.log(e);
    }
    setIsLoading(false);
  };

  const handleFetchBranches = async (query: string) => {
    const endpoint = `${API_ENDPOINT}/api/org/branches/?page_size=10&name=${encodeURIComponent(query)}`;

    try {
      const response = await axios.get<{
        count: number;
        results: BranchType[];
      }>(endpoint, config);

      console.log(response);

      if (response.statusText === 'OK') {
        return response.data.results;
      }

      return [];
    } catch (error) {
      console.log(error);
    }

    return [];
  };

  return isLoading ? (
    <LinearProgress />
  ) : (
    <>

      <FormControl fullWidth>
        <AutocompleteWithThrottle
          label={'Branch'}
          onClickAdd={() => setCreatingNewBranchOpen(true)}
          fetch={handleFetchBranches}
          value={branch ?? null}
          getOptionLabel={(option: BranchType) =>
            option.name + ' - ' + option.address
          }
          onChange={(e, value) => {
            setBranch(value);
          }}
        />
      </FormControl>
      <br></br>
      <br />

      {message != '' && (
        <>
          <Alert
            severity={
              response && response.status >= 200 && response.status < 300
                ? 'success'
                : 'error'
            }
          >
            <>
              {message}

              {response &&
                response.status >= 300 &&
                Object.keys(response.data).map((key) => (
                  <>
                    <ul>
                      {
                        typeof response.data[key] === "string" ?
                          <li>{response.data[key]}</li> :
                          response.data[key].map((validationError: string) => (
                            <li key={key}>
                              {key}: {validationError}
                            </li>
                          ))}
                    </ul>
                  </>
                ))}
            </>
          </Alert>
          <br /> </>
      )}


      <FormControl>
        <Button
          variant="contained"
          disabled={!branch}
          type="submit"
          onClick={() => branch && handleSubmit()}
        >
          {' '}
          Apply{' '}
        </Button>
      </FormControl>

      <Dialog fullWidth open={creatingNewBranchOpen} >
        <div
          style={{
            textAlign: 'right',
            width: '100%',
          }}
        >
          <IconButton onClick={() => setCreatingNewBranchOpen(false)}>
            <ClearIcon color="primary" fontSize="large" />
          </IconButton>
        </div>

        <DialogContent>
          <CreateBranchForm />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default SetBranchAction;
