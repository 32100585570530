import DownloadIcon from '@mui/icons-material/Download';
import { FormControl, Button } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { useContext } from 'react';
import { AuthContext } from '../../../contexts/user-context/user-context';
import { UserType } from '../../../enums/users';
import { OrderType } from '../../../interfaces/delivery-company/orders';
import { defaultMerchantReportFieldsConfig, defaultOrgReportFieldsConfig } from '../report-builder/fields-config';
import { ReportDocument } from '../report-builder/report';
import { compute_total_price_usd, compute_total_price_lbp, compute_total_delivery_fee_usd, compute_total_delivery_fee_lbp } from '../report-builder/utils';
import { BulkActionProps } from './props.interface';


function ExtractAsPDFAction({
  selectedOrders,
  extractAsPdf,
}: BulkActionProps) {
  const { user } = useContext(AuthContext);
  const orgId = user?.organisation?.org_id;

  const fileName =
    extractAsPdf?.fileName ??
    `parcel-tracer-extract.${new Date().toLocaleString()}.pdf`;
  const orders = Array.from(selectedOrders.values());

  return (
    <FormControl fullWidth>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {
          <PDFDownloadLink
            document={
              <ReportDocument
                date_from={extractAsPdf?.date ?? ''}
                date_to={extractAsPdf?.date ?? ''}
                organisation_id={
                  user?.organisation?.org_id ?? orgId ?? 'UNKNOWN'
                }
                title={'EXTRACTION REPORT'}
                subtitles={
                  extractAsPdf?.subtitle ? [extractAsPdf?.subtitle] : undefined
                }
                tables={[
                  {
                    orders: orders,
                    fields_config:
                      extractAsPdf?.fields_config ??
                      (user?.type === UserType.MERCHANT
                        ? defaultMerchantReportFieldsConfig
                        : defaultOrgReportFieldsConfig),
                    header_bg: '#eee',
                    title: extractAsPdf?.tableTitle ?? 'Extracted Orders',
                    table_footer_rows: extractAsPdf?.table_footer_rows ?? [
                      {
                        label: 'total orders',
                        compute_value: (orders: OrderType[]) =>
                          orders.length.toString(),
                      },
                      {
                        label: 'price',
                        compute_value: (orders) =>
                          `${compute_total_price_usd(orders)} $ + ${compute_total_price_lbp(orders)} L.L`,
                      },
                      {
                        label: 'delivery fee',
                        compute_value: (orders) =>
                          `${compute_total_delivery_fee_usd(orders)} $ + ${compute_total_delivery_fee_lbp(orders)} L.L`,
                      },
                    ],
                  },
                ]}
              />
            }
            fileName={fileName}
          >
            {({ blob, url, loading, error }) => {
              return loading ? (
                <Button color="primary" size="large" disabled>
                  Loading...
                </Button>
              ) : (
                <Button color="primary" size="large">
                  <DownloadIcon fontSize="large" />
                </Button>
              );
            }}
          </PDFDownloadLink>
        }
      </div>
    </FormControl>
  );
}

export default ExtractAsPDFAction;
